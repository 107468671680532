<template>
  <div>
    <!--    <v-text-field type="text" outlined height="32" hide-details placeholder="Destination" ref="origin"/>-->
    <label>
      <input type="text" class="type-2" placeholder="ค้นหาพื้นที่" ref="origin"/>
    </label>
    <div id="map" class="Map">
      <MapInfoWindow v-if="clickedOnMap" :lat="clickLatLng.lat" :lng="clickLatLng.lng">
        <v-btn @click="calDistance()" small height="26" color="success">เลือกจุดนี้</v-btn>
      </MapInfoWindow>
    </div>
  </div>
</template>

<script>
import gmapsInit from "../plugins/gmap";
import MapInfoWindow from "@/components/MapInfoWindow";

export default {
  props: ['latLng', 'viewMode'],
  name: "Map",
  components: {MapInfoWindow},
  data() {
    return {
      map: null,
      startLocation: {lat: 13.921458468650471, lng: 100.6899946229607},
      clickedOnMap: false,
      clickLatLng: {},
      google: null,
      directionsService: null,
      directionsDisplay: null,

    }
  },
  mounted() {
    this.initMap()

    // const autocomplete = new google.maps.places.Autocomplete(this.$refs["origin"]);

    // for (let ref in this.$refs) {
    //   const autocomplete = new this.google.maps.places.Autocomplete(this.$refs[ref]);
    //
    //   autocomplete.addListener("place_changed", () => {
    //     const place = autocomplete.getPlace();
    //     console.log(place);
    //   })
    // }

  },
  async created() {
    // await this.initMap()
    this.clickLatLng = this.startLocation

  },
  methods: {

    async initMap() {

      try {
        this.google = await gmapsInit();
        // let myLatLng = {lat: 13.788532667269767, lng: 100.2540196505897};


        // this.map.setCenter(myLatLng);
        //
        // let infoWindow = new google.maps.InfoWindow();
        //
        if (this.viewMode) {
          if (this.latLng.lat !== null) {
            let mapOptions = {
              center: this.latLng,
              zoom: 13,
              mapTypeId: this.google.maps.MapTypeId.ROADMAP
            };

            this.map = new this.google.maps.Map(document.getElementById('map'), mapOptions)
            this.setMarker(this.latLng, "https://i.ibb.co/Qdbyff2/flag-1.png")
          }
        } else {

          // console.log(autocomplete)
          let mapOptions = {
            center: this.startLocation,
            zoom: 13,
            mapTypeId: this.google.maps.MapTypeId.ROADMAP
          };

          this.map = new this.google.maps.Map(document.getElementById('map'), mapOptions)

          //create a DirectionsService object to use the route method and get a result for our request
          this.directionsService = new this.google.maps.DirectionsService();

// //create a DirectionsRenderer object which we will use to display the route
          this.directionsDisplay = new this.google.maps.DirectionsRenderer({
            suppressMarkers: true
          });


// //bind the DirectionsRenderer to the map
          this.directionsDisplay.setMap(this.map);
          this.map.addListener("click", (mapsMouseEvent) => {
            this.clickedOnMap = true
            this.clickLatLng = mapsMouseEvent.latLng.toJSON()
          });

          const options = {
            componentRestrictions: {country: "th"},
            fields: ["address_components", "geometry", "icon", "name"],
            strictBounds: false,
            types: ["establishment"],
          };
          const autocomplete = new this.google.maps.places.Autocomplete(this.$refs["origin"], options);
          autocomplete.setFields(["place_id", "geometry", "name"]);

          autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            console.log(place);
            if (!place.geometry || !place.geometry.location) {
              // User entered the name of a Place that was not suggested and
              // pressed the Enter key, or the Place Details request failed.
              window.alert("No details available for input: '" + place.name + "'");
            }

            if (place.geometry.viewport) {
              this.map.fitBounds(place.geometry.viewport);
            } else {
              this.map.setCenter(place.geometry.location);
              this.map.setZoom(17);
            }
          })
        }

        this.setMarker(this.startLocation, "https://i.ibb.co/gT7kXrN/location.png")


      } catch (e) {
        console.log(e)
      }
    },
    getMap(callback) {
      let vm = this

      function checkForMap() {
        if (vm.map) callback(vm.map)
        else setTimeout(checkForMap, 200)
      }

      checkForMap()
    },
    setMarker(point, url) {
      var icon = {
        url: url, // url
        scaledSize: new this.google.maps.Size(40, 40), // scaled size
        // origin: new this.google.maps.Point(20, 20), // origin
        // anchor: new this.google.maps.Point(40, 40) // anchor
      };
      let markers = new this.google.maps.Marker({
        position: point,
        map: this.map,
        // label:{
        //   text:label,
        //   color:"#FFF"
        // },
        icon: icon
      })
    },
    calDistance() {
      let request = {
        origin: this.startLocation,
        destination: this.clickLatLng,
        travelMode: this.google.maps.TravelMode.DRIVING, //WALKING, BYCYCLING, TRANSIT
        unitSystem: this.google.maps.UnitSystem.METRIC
      }
      this.directionsService.route(request, (result, status) => {
        if (status === this.google.maps.DirectionsStatus.OK) {
          let meter = result.routes[0].legs[0].distance.value
          // console.log(this.directionsDisplay)
          this.directionsDisplay.setDirections(result);
          this.$emit("getDistance", {distance: meter / 1000, latlng: this.clickLatLng})
        } else {
          //delete route from map
          this.directionsDisplay.setDirections({routes: []});
          //center map in London
          this.map.setCenter(this.startLocation);

          console.log("Could not retrieve driving distance")

          //show error message
          // output.innerHTML = "<div class='alert-danger'><i class='fas fa-exclamation-triangle'></i> Could not retrieve driving distance.</div>";
        }
      });
    }
  },
  watch: {
    viewMode() {
      console.log('change')
      this.initMap()
    }
  }
}
</script>

<style scoped>
.Map {
  width: 100%;
  height: 400px;
  border-radius: 10px;

}

.type-2 {
  color: #333;
  width: 100%;
  margin-bottom: 1rem;
  padding: .45rem .6rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: 2px solid #1C6EA4;
  display: block;
}
</style>