<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        ข้อมูลลูกค้า
      </v-toolbar-title>
    </v-toolbar>
    <div class="mx-4 box box-2" style="min-height: 100%;">
      <v-row dense class="box box-2" style="height: 100%;display: flex;flex-direction: row">
        <v-col
            cols="12"
            style="height: 100%"
        >
          <v-card
              style="height: 100%;overflow: auto"
              class="px-4 pt-4 rounded-lg elevation-2"
              outlined
              tile
          >
            <!--            {{$v.name.$error}}-->
            <!--            <p class="help is-danger" v-if="!$v.name.required">This field is required</p>-->
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-row>
                <v-col cols="3" align-self="center">ชื่อลูกค้า</v-col>
                <v-col cols="5">
                  <v-text-field v-model="name" :rules="[rules.required]" required
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">เบอร์</v-col>
                <v-col cols="5">
                  <v-text-field v-model="tel" :rules="[rules.required]" required
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">เครดิต</v-col>
                <v-col cols="5">
                  <v-text-field v-model="credit" required
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                suffix="วัน"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">เงินเติม</v-col>
                <v-col cols="5">
                  <v-text-field v-model="prepaid" required
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                suffix="บาท"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">ประเภทของลูกค้า</v-col>
                <v-col cols="5">
                  <v-select
                      :rules="[rules.required_select]"
                      outlined
                      dense
                      height="32px"
                      class="rounded mt-3"
                      hide-details="auto"
                      v-model="type"
                      :items="typeOption"
                      placeholder="เลือกหมวดสินค้า"
                      item-text="name"
                      item-value="name"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">เลขประจำตัวผู้เสียภาษี</v-col>
                <v-col cols="5">
                  <v-text-field v-model="tex_number" required
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">ยอดชำระสูงสุด</v-col>
                <v-col cols="5">
                  <v-text-field v-model="max_purchase_amount"
                                type="number"
                                placeholder="กรอกข้อมูล"
                                outlined height="32px"
                                hide-details="auto"/>
                </v-col>
              </v-row>
            </v-form>

            <v-row class="mt-4">
              <v-col align-self="center">
                <span class="mr-2">ที่อยู่ ({{ customerAddress.length }})</span>
                <v-icon @click="showNewAddressForm = true" color="success">mdi-plus-circle</v-icon>
              </v-col>
            </v-row>

            <v-data-table
                hide-default-footer
                :headers="addressHeader"
                :items="customerAddress"
            >
              <!--              <template v-slot:item.is_tax_address="{ item }">-->
              <!--                <v-checkbox v-model="item.is_tax_address">-->

              <!--                </v-checkbox>-->
              <!--              </template>-->
              <template v-slot:item.actions="{ item }">
                <div class="d-flex">
                  <v-btn
                      icon
                      color="red"
                      @click="selectedAddress = item;deleteConfirmDialog = true"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>

            </v-data-table>

            <v-card-actions class="mt-8">
              <v-btn text>ยกเลิก</v-btn>
              <v-btn @click="addCustomer()" depressed dense color="success">
                <v-icon left>mdi-content-save</v-icon>
                เพิ่มข้อมูลลูกค้า
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>


    <!--    Add new Address From-->
    <v-dialog v-model="showNewAddressForm" persistent max-width="842">
      <v-card>
        <v-card-title class="text-h5" style="font-family: Kanit,serif !important;">
          เพิ่มที่อยู่ใหม่
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-textarea hide-details="auto" class="mb-4" v-model="newAddress" outlined placeholder="ที่อยู่"
                        rows="2"></v-textarea>

            <v-text-field class="mb-6" placeholder="เบอร์โทรศัพท์ของที่อยู่ (ไม่จำเป็น)" v-model="newTelAddress"
                          outlined
                          height="32" hide-details></v-text-field>

            <div class="my-2">เลือกตำบลจากที่ระบบมีให้</div>

            <v-row class="mx-0 mb-6 mt-2">
              <thai-address-input
                  placeholder="ใส่ตำบล"
                  type="subdistrict"
                  v-model="newSearchAddress"
                  input-class="form-control"
                  @selected="onSearched">
              </thai-address-input>
            </v-row>

            <div class="my-2">สามารถกรอกเพิ่มเติมได้</div>

            <v-row v-if="newSearchResult" class="mb-2 mx-0">
              <v-col cols="6" class="pl-0">
                <v-text-field v-model="newSearchResult.subdistrict" outlined height="32" hide-details placeholder="ตำบล"/>
              </v-col>
              <v-col cols="6" class="pr-0">
                <v-text-field v-model="newSearchResult.district" outlined height="32" hide-details placeholder="อำเภอ"/>
              </v-col>
              <v-col cols="6" class="pl-0">
                <v-text-field v-model="newSearchResult.province" outlined height="32" hide-details placeholder="จังหวัด"/>
              </v-col>
              <v-col cols="6" class="pr-0">
                <v-text-field v-model="newSearchResult.postalCode" outlined height="32" hide-details placeholder="รหัสไปรษณีย์"/>
              </v-col>
            </v-row>


            <!--            <v-expansion-panels flat>-->
            <!--              <v-expansion-panel>-->
            <!--                <v-expansion-panel-header class="px-0">-->
            <!--                  เลือกจุดหมายบนแผนที่-->
            <!--                  <template v-slot:actions>-->
            <!--                    <v-icon color="primary">-->
            <!--                      $expand-->
            <!--                    </v-icon>-->
            <!--                  </template>-->
            <!--                </v-expansion-panel-header>-->
            <!--                <v-expansion-panel-content class="pb-0">-->
            <!--                  <Map @getDistance="getDistance" :view-mode="false"/>-->
            <!--                </v-expansion-panel-content>-->
            <!--              </v-expansion-panel>-->
            <!--            </v-expansion-panels>-->
            <div class="mt-2">ระยะทาง</div>
            <v-text-field class="mt-1" v-model="newDistance" required type="number"
                          placeholder="กรอกข้อมูล"
                          outlined height="32px"
                          hide-details="auto"/>

            <div class="mt-2">Google Map Link</div>
            <v-textarea
                placeholder="ลิ้งจาก Google Map"
                hide-details
                rows="2"
                outlined
                v-model="newLinkGoogleMap"
            ></v-textarea>
          </v-form>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="showNewAddressForm = false"
          >
            ยกเลิก
          </v-btn>
          <v-btn
              color="success"
              @click="showNewAddressForm = false;addNewAddress()"
          >
            เพิ่มที่อยู่ใหม่
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--    Confirm Delete address-->
    <v-dialog v-model="deleteConfirmDialog" persistent max-width="625">
      <v-card>
        <v-card-title class="text-h5" style="font-family: Kanit,serif !important;">
          นำที่อยู่นี้ออก
        </v-card-title>
        <v-card-text>"{{ showAddressBeforeDelete() }}"</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteConfirmDialog = false" text>
            ยกเลิก
          </v-btn>
          <v-btn @click="removeAddress()" color="error">
            นำออก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import Map from "@/components/Map";

export default {
  components: {Map},
  name: "CreateCustomer",
  data() {
    return {
      valid: true,
      rules: {
        required: value => value.length > 0 || "ต้องกรอก",
        required_select: value => !!value || "เลือกหมวดสินค้า",
        min: value => value >= 0 || "จำนวนต้องเป็นตั้งแต่ 0 ขึ้นไป"
      },
      name: '',
      tel: '',
      credit: 0,
      prepaid: 0,
      type: 'ทั่วไป',
      tex_number: '',
      typeOption: [{
        name: 'ทั่วไป'
      }],
      customerAddress: [],
      addressHeader: [
        {
          text: 'ที่อยู่',
          value: 'address'
        },
        {
          text: 'ตำบล/แขวง',
          value: 'district'
        }, {
          text: 'อำเภอ/เขต',
          value: 'county'
        }, {
          text: 'จังหวัด',
          value: 'province'
        }, {
          text: 'รหัสไปรษณีย์',
          value: 'code'
        }, {
          text: 'ระยะทาง',
          value: 'distance'
        },
        {
          text: 'ใบกำกับภาษี',
          value: 'is_tax_address'
        },
        {
          text: '',
          value: 'actions'
        }
      ],
      showNewAddressForm: false,
      newAddress: '',
      newSearchAddress: '',
      newSearchResult: {},
      newDistance: 0,
      newLatlngSelect: {},
      newLinkGoogleMap: '',
      newIsTax: false,
      newTelAddress:'',
      selectedAddress: {},
      deleteConfirmDialog: false,
      max_purchase_amount: ''
    }
  },
  methods: {
    onSearched(address) {
      this.newSearchAddress = address.subdistrict
      this.newSearchResult = address;
    },
    getDistance(value) {
      this.newDistance = value.distance
      this.newLatlngSelect = value.latlng
    },
    addNewAddress() {
      this.customerAddress.push({
        address: this.newAddress,
        district: this.newSearchResult.subdistrict || '',
        county: this.newSearchResult.district || '',
        province: this.newSearchResult.province || '',
        code: this.newSearchResult.postalCode || '',
        latitude: this.newLinkGoogleMap || '',
        longitude: parseFloat(this.newLatlngSelect.lng) || '',
        distance: parseFloat(this.newDistance).toFixed(2) || 0,
        is_tax_address: this.newIsTax,
        tel: this.newTelAddress || ''
      })

      this.clearFromAddress()
    },
    clearFromAddress() {
      this.newAddress = ''
      this.newSearchAddress = ''
      this.newSearchResult = {}
      this.newDistance = 0
      this.newLatlngSelect = {}
      this.newIsTax = false
      this.newTelAddress = ''
    },
    showAddressBeforeDelete() {
      return `${this.selectedAddress.address} ${this.selectedAddress.district} ${this.selectedAddress.county} ${this.selectedAddress.province} ${this.selectedAddress.code}`
    },
    removeAddress() {
      let index = this.customerAddress.indexOf(this.selectedAddress)
      this.customerAddress.splice(index, 1)
      this.deleteConfirmDialog = false
    },
    async addCustomer() {
      await this.$refs.form.validate()
      if (this.valid) {
        let payload = {
          name: this.name,
          tel: this.tel,
          credit: parseInt(this.credit),
          prepaid: parseInt(this.prepaid),
          type: this.type,
          tax_number: this.tex_number,
          customer_address: this.customerAddress,
          max_purchase_amount: this.max_purchase_amount
        }
        console.log(payload)
        axios.post('/api/accounts/customer/', payload)
            .then(res => {
              console.log(res.data.data)
              this.$router.push({path: '/setting/customer'})
              this.$store.state.snackbar = {
                text: 'เพิ่มข้อมูลลูกค้าสำเร็จ',
                visible: true,
                color: 'success'
              }
            })
            .catch(e => {
              console.error(e)
              this.$store.state.snackbar = {
                text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
                visible: true,
                color: 'error'
              }
            })
      }

    }
  }
}
</script>

<style scoped>

</style>

<style>


.form-control {
  padding: 3px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  width: 100%;
}

.suggestion-list {
  text-align: left;
  padding: 4px;
  position: fixed;
  background-color: white;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 4px;
}

.suggestion-list-item:hover {
  cursor: pointer;
  background-color: #c7ddef;
}

.suggestion-list {
  max-height: 200px;
  overflow: auto;
}
</style>
